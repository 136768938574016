import React, { useState, useEffect, useCallback } from "react"
//import tippy from "tippy.js"
import 'tippy.js/dist/tippy.css';
import { Typography, Box, ListItemIcon, ListItemText, LinearProgress } from "@mui/material"
import MuiAppBar from "@mui/material/AppBar"
import CssBaseline from '@mui/material/CssBaseline'
import { makeStyles } from "@material-ui/core/styles"
import { styled, useTheme } from "@mui/material/styles"
//import List from "@mui/material/List"
//import ListItem from "@mui/material/ListItem"
//import ListItemIcon from "@mui/material/ListItemIcon"
//import ListItemText from "@mui/material/ListItemText"
import { Computer } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router"
import ToolBar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import MenuIcon from '@mui/icons-material/Menu'
import { Menu } from "@mui/material"
import { MenuItem } from "@mui/material"
import Snackbar from '@mui/material/Snackbar';
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
// import DarkModeIcon from '@mui/icons-material/DarkMode'
// import LightModeIcon from '@mui/icons-material/LightMode'
import AlarmBackdrop from './AlarmBackdrop'
import LogoCliente from '../assets/logo-guardiao-2-h.png'
import LogoTecnico from '../assets/tecnico.png'
import LogoRevenda from '../assets/logo-integrador-v2.png'
import bkImage from "../assets/logoDelta2.png"
import bkImageDark from "../assets/logoDeltaOpaco.png"
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import IconButton from '@mui/material/IconButton'
import Footer from "./Footer"
import PanelCommands from "./PanelCommands"
import Drawer from "../includes/Drawer";
import OccurencesHandler from "../pages/OccurencesHandler";
import Modal from "../includes/Modal";

const drawerWidth = 240

const useStyles = makeStyles((theme) => {
    return {
        pageLayout: {
            backgroundImage: `url(${bkImage})`,
            backgroundPosition: 'center center',
            [theme.breakpoints.up('sm')]: {
                backgroundSize: 'auto'
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: '80vw'
            },
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: "fixed",
            backgroundColor: '#FFFFFF',
            width: '100vw',
            paddingTop: theme.spacing(1),
            paddingBottom: 50,
        },
        pageLayoutDark: {
            backgroundImage: `url(${bkImageDark})`,
            backgroundPosition: 'center center',
            [theme.breakpoints.up('sm')]: {
                backgroundSize: 'auto'
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: '80vw'
            },
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: "fixed",
            backgroundColor: '#212121',
            backgroundBlendMode: 'color-dodge',
            width: '100vw',
            paddingTop: theme.spacing(1),
            paddingBottom: 50,
        },
    }
})

const ImageWrapper = styled('img')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        maxWidth: '100px',
        maxHeight: '50px',
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: '90px',
        maxHeight: '40px',
    }
}));

const PageMargin = styled('div')(({ theme }) => ({
    minHeight: '64px'
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    border: '1px solid',
    borderColor: '#5E5E5E',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const CheckTypeOfView = function() {

    if (navigator.maxTouchPoints > 0) {
        // Dispositivo possui tela touchscreen
        console.log("Tela touchscreen detectada!");
        localStorage.setItem('df-guardiao-type-view','touch');
    } 
    else 
    {
        // Dispositivo não possui tela touchscreen
        console.log("Dispositivo não possui tela touchscreen.");
        localStorage.setItem('df-guardiao-type-view','normal');
    }
}

export default function Layout({ setDarkMode, darkMode, serial, userRole, children, logout, companyId, userId, user, token, rows, setRows, clearState, generatedCommand, clearBackdrop, systemData, modemStatus, setModemStatus, modemConnected, setModemConnected, occurrenceController, setOccurrenceController }) {
    const [open, setOpen] = useState(false)
    const [flag, setFlag] = useState(false)
    //const [tooltips, setTooltips] = useState([]);
    const [notifyNetworkData, setNotifyNetworkData] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorUserEl, setAnchorUserEl] = useState(null)
    const [touchMenuControl, setTouchMenuControl] = useState(false);
    const [disabledOcurrenceBackdrop, setDisabledOcurrenceBackdrop] = useState(true);
    const [goAway, setGoAway] = useState(true);
    const mobileMenuOpen = Boolean(anchorEl)
    const classes = useStyles(open)
    const history = useHistory()
    const location = useLocation()
    const theme = useTheme()
    const system = useState(systemData);
    const [hasUpdate, setHasUpdate] = useState({update: false, internet: false, version: ""});
    const [isLoading, setIsLoading] = useState(false);
    const [modalUpdateMessage, setModalUpdateMessage] = useState("");

    const handleDrawerOpen = () => {
        setOpen(!open)
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseUser = (path) => {
        setAnchorUserEl(null)
    }

    const handleClose = (path) => {
        setAnchorEl(null)
        history.push(path)
    }

    const handleMenu = (event) => {
        setAnchorUserEl(event.currentTarget);
    }

    const handleReturn = () => {
        history.push("/painel")
    }

    const handleDarkMode = () => {
        setDarkMode(!darkMode)
    }

    const closeSnackBarNetwork = () => {
        setNotifyNetworkData(false);
    }
    
    const menuLightVersion = [
        {
            text: 'Sistema',
            icon: <i class="df-i df-i-xl df-i-system"></i>,
            path: '/central',
            mode: 'all'
        },
        {
            text: 'Dashboard',
            icon: <i class="df-i df-i-xl df-i-dashboard"></i>,
            path: '/dashboard',
            mode: 'all'
        },
        //WEB
        {
            text: 'Emergência',
            icon: <i class="df-i df-i-xl df-i-emergency" data-type="comandos"></i>,
            path: '/sem+permissao',
            mode: 'desktop'
        },
        {
            text: 'Alarmes',
            icon: <VolumeUpIcon color="secondary" />,
            path: '/sem+permissao',
            mode: 'all'
        },
        {
            text: 'Eventos',
            icon: <i class="df-i df-i-xl df-i-events"></i>,
            path: '/sem+permissao',
            mode: 'all'
        },
        {
            text: 'Periféricos',
            icon: <i class="df-i df-i-xl df-i-peripheral"></i>,
            path: '/sem+permissao',
            mode: 'all'
        },
        {
            text: 'Grupos',
            icon: <i class="df-i df-i-xl df-i-groups"></i>,
            path: '/sem+permissao',
            mode: 'all'
        },
        {
            text: 'Rede',
            icon: <i class="df-i df-i-xl df-i-network"></i>,
            path: '/sem+permissao',
            mode: 'all'
        },
        {
            text: 'Relatórios',
            icon: <i class="df-i df-i-xl df-i-relatory"></i>,
            path: '/relatorios',
            mode: 'all'
        },
        //WEB
        {
            text: 'Configurações',
            icon: <i class="df-i df-i-xl df-i-config"></i>,
            path: '/usuarios',
            mode: 'desktop'
        },
        //WEB
        {
            text: 'Terminal Fire',
            icon: <Computer color="secondary" />,
            path: '/terminal',
            mode: 'desktop'
        },
    ]

    const menuItens = [
        {
            text: 'Sistema',
            icon: <i class="df-i df-i-xl df-i-system"></i>,
            path: '/central',
            mode: 'all'
        },
        {
            text: 'Dashboards',
            icon: <i class="df-i df-i-xl df-i-dashboard"></i>,
            path: '/dashboard',
            mode: 'all'
        },
        //WEB
        {
            text: 'Emergência',
            icon: <i class="df-i df-i-xl df-i-emergency" data-type="comandos"></i>,
            path: '/comando',
            mode: 'desktop'
        },
        {
            text: 'Alarmes',
            icon: <VolumeUpIcon color="secondary" />,
            path: '/alarmes',
            mode: 'all'
        },
        {
            text: 'Eventos',
            icon: <i class="df-i df-i-xl df-i-events"></i>,
            path: '/eventos',
            mode: 'all'
        },
        {
            text: 'Periféricos',
            icon: <i class="df-i df-i-xl df-i-peripheral"></i>,
            path: '/registros',
            mode: 'all'
        },
        {
            text: 'Grupos',
            icon: <i class="df-i df-i-xl df-i-groups"></i>,
            path: '/grupos',
            mode: 'all'
        },
        {
            text: 'Rede',
            icon: <i class="df-i df-i-xl df-i-network"></i>,
            path: '/amplificadores',
            mode: 'all'
        },
        {
            text: 'Relatórios',
            icon: <i class="df-i df-i-xl df-i-relatory"></i>,
            path: '/relatorios',
            mode: 'all'
        },
        //WEB
        {
            text: 'Configurações',
            icon: <i class="df-i df-i-xl df-i-config"></i>,
            path: '/usuarios',
            mode: 'desktop'
        },
        {
            text: 'Configurações',
            icon: <i class="df-i df-i-xl df-i-config"></i>,
            path: '/configuracoes',
            mode: 'web'
        },
        //WEB
        {
            text: 'Terminal Fire',
            icon: <Computer color="secondary" />,
            path: '/terminal',
            mode: 'desktop'
        },
    ]

    const GetCoordinator = async (serialNumber, userToken) => {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/coordinator?centralSerial=${serialNumber}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + userToken
                },
            });

            if (!response.ok)
            {
                throw new Error('Erro ao buscar informações do Coordenador');
            }

            const res = await response.json();

            return {
                "panelStatus": res.dS_STATUS,
                "coordinator": res
            }
        }
        catch (error)
        {
            console.log("🚀 ~ GetCoordinator ~ error:", error); 
            return false; 
        }
    };

    const GetAmplifiersByCentralSerial = async (serialNumber, userToken) => {
        try 
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/all`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "authorization": `Bearer ${userToken}`
                },
                body: JSON.stringify({ "serialNumber": serialNumber })
            });
            
            if (!response.ok) {
                throw new Error('Erro ao buscar amplificadores');
            }
    
            const res = await response.json();
    
            return {
                "all": res.filter(amplifier => amplifier.dS_NOME !== "Modem" && amplifier.dS_NOME !== "Central"),
                "online": res.filter(amplifier => (amplifier.dS_STATUS === "online" || amplifier.dS_STATUS === "buscando") && amplifier.dS_NOME !== "Modem" && amplifier.ds_NOME !== "Central")
            };
        } 
        catch (error) 
        {
            console.error("Erro ao buscar amplificadores:", error);
            return false;
        }
    }

    const RefreshNetworkData = (e) => { 
        if (!!sessionStorage.getItem("serial"))
        {
            GetNetworkData(sessionStorage.getItem("serial"), token);
        }
        else
        {
            setTimeout(RefreshNetworkData,10000)
        }
    }

    const GetNetworkData = useCallback(async (serialNumber, userToken) => { 
        let ico = document.querySelector('.header-action[data-action="refresh-system"] i');

        ico.classList.add("fa-spin");

        let coordinator = await GetCoordinator(serialNumber,userToken);
        let amplifiers = await GetAmplifiersByCentralSerial(serialNumber,userToken);        

        sessionStorage.setItem("coordinator",JSON.stringify(coordinator));
        sessionStorage.setItem("amplifiers", JSON.stringify(amplifiers)); 
        
        ico.classList.remove("fa-spin");
        setNotifyNetworkData(true); 
    },[])

    const ControlActionButton = useCallback(function(occurrence){

        let actionButton = document.querySelector('.header-action[data-action="system-alerts"]');
        
        if (!actionButton) return;
        
        switch(occurrence)
        {
            case "BATERIA BAIXA":
            case "REMOVIDO":
            case "CONTROLE AMBIENTAL":
            case "DISPARO MANUAL":
            case "SEM REDE AC":
            case "PANI":
            case "LIGA":
            case "SILE":
                if(!actionButton.classList.contains("header-action--warning"))
                {
                    actionButton.classList.add("header-action--warning");
                    actionButton.classList.add("header-action--animation");
                }
                break;
            case "DESL":
                if(actionButton.classList.contains("header-action--warning"))
                {
                    actionButton.classList.remove("header-action--warning");
                    actionButton.classList.remove("header-action--animation");
                }
                break;
            default:
                break;
        }

    },[]);

    const ControlModemButton = useCallback(() => {
        let serialPort = sessionStorage.getItem("serialPortNumber");
        let action = document.querySelector('.header-action[data-action="port-com"]');
        
        if(!!action)
        {
            if (action.classList.contains("header-action--danger"))
            {
                action.classList.remove("header-action--danger");
            }
            
            if (action.classList.contains("header-action--success"))
            {
                action.classList.remove("header-action--success");
            }
    
            if(!modemStatus.status)
            {
                action.classList.add("header-action--danger");
                //action.dataset.tippyContent = "MODEM USB: ERRO COM O MODEM";
                action.title = "MODEM USB: ERRO COM O MODEM";
    
                if(!!modemStatus.checked)
                {
                    //alert("O MODEM FOI DESCONECTADO!\n\nVerifique e REINICIE o Guardião!");
                }
            }
            else
            {
                switch(serialPort)
                {
                    case null:
                        action.classList.add("header-action--danger");
                        //action.dataset.tippyContent = "MODEM USB: PORTA COM NÃO FOI INFORMADA";
                        action.title = "MODEM USB: PORTA COM NÃO FOI INFORMADA";
                        break;
                    default:
                        action.classList.add("header-action--success");
                        //action.dataset.tippyContent = "MODEM USB: CONECTADO";
                        action.title = "MODEM USB: CONECTADO";
                        break
                }
            }
        }
        // setTimeout(() => {
        // },5000);
    },[modemStatus]);

    const CheckPortCOM = useCallback(async () => {
        try
        {
            if (!!sessionStorage.getItem("serial"))
            {
                const response = await fetch(`${process.env.REACT_APP_URL}/account/ports/connection?centralSerial=${sessionStorage.getItem("serial")}`, {
                    method: 'GET',
                    headers: {
                      "content-type": "application/json",
                      "Authorization": "Bearer " + token
                    }
                });
    
                if (!response.ok)
                {
                    throw new Error("Erro ao buscar o Status do Modem");
                }
    
                const res = await response.json();
    
                if (res !== null)
                {
                    setModemStatus({status: res, checked: true});
                }
            }
            else
            {
                throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
            }
        }
        catch (error)
        {
            console.log("🚀 ~ GetModemStatus ~ error:", error)
        }
        finally
        {
            if (process.env.REACT_APP_MODE === "desktop")
            {
                setTimeout(CheckPortCOM,2000);
            }
        }
    },[token, modemStatus]);

    const CheckModemConnection = useCallback(async () => {
        try
        {
            if (!!sessionStorage.getItem("serial"))
            {
                const response = await fetch(`${process.env.REACT_APP_URL}/account/modem/connection?centralSerial=${sessionStorage.getItem("serial")}`, {
                    method: 'GET',
                    headers: {
                      "content-type": "application/json",
                      "Authorization": "Bearer " + token
                    }
                });
    
                if (!response.ok)
                {
                    throw new Error("Erro ao buscar o Status da Conexão do Modem");
                }
    
                const res = await response.json();

                if (res !== null)
                {
                    setModemConnected(res);
                }
                else 
                {
                    setModemConnected("não informado");
                }
            }
            else
            {
                throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
            }
        }
        catch (error)
        {
            console.log("🚀 ~ CheckModemConnection ~ error:", error)
            setModemConnected("não informado");
        }
        finally
        {
            setTimeout(CheckModemConnection, 60000)
        }
    },[]);

    const VerifyUpdate = async function () {
        setAnchorUserEl(null);
        setIsLoading(true);

        try
        {
            const systemData = JSON.parse(localStorage.getItem("system"));
            const licenseType = localStorage.getItem("licenseType");

            if (!systemData.licenceKey)
            {
                throw Error("Não foi possível obter a licença do produto");
            }
            if (!licenseType)
            {
                throw Error("Não foi possível obter o tipo de licença do produto");
            }

            const response = await fetch(`${process.env.REACT_APP_URL}/licences/verify-version-to-update`, {
                method: 'POST',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    licenseKey: systemData.licenceKey,
                    licenseType: licenseType,
                    licenseRevision: process.env.REACT_APP_BUILD_VERSION
                })
            });
            
            if (!response.ok)
            {
                throw Error("Não foi possível verificar se há updates!");
            }

            const res = await response.json();

            if (!res.internet)
            {
                setModalUpdateMessage("Verifique sua conexão de internet.<br/>Caso a conexão não seja possível, entre em contato com o Instalador<br/>para verificar a necessidade de atualização.");
            }
            else if (!res.update)
            {
                setModalUpdateMessage("Seu Guardião 2.0 está em dia!");
            }
            else
            {
                setModalUpdateMessage(`Seu Guardião 2.0 tem uma nova atualização [${res.version}].<br/>Deseja atualizar agora?`);
            }

            setHasUpdate(res);
        }
        catch (error)
        {
            console.log("🚀 ~ VerifyUpdate ~ error:", error)
            setModalUpdateMessage("Verifique sua conexão de internet.<br/>Caso a conexão não seja possível, entre em contato com o Instalador<br/>para verificar a necessidade de atualização.");
            setHasUpdate({update: false, internet: false, version: ""});
        }
        finally
        {
            setIsLoading(false);
            document.querySelector('#modal-update-guardiao[data-control="modal"]').checked = true;
        }
    }

    const ExecuteUpdater = async function()  {
        try 
        {
            document.querySelector('#modal-update-guardiao[data-control="modal"]').checked = false;
            setIsLoading(true);

            const systemData = JSON.parse(localStorage.getItem("system"));
            const licenseType = localStorage.getItem("licenseType");

            if (!systemData.licenceKey)
            {
                throw Error("Não foi possível obter a licença do produto");
            }
            if (!licenseType)
            {
                throw Error("Não foi possível obter o tipo de licença do produto");
            }

            await fetch(`${process.env.REACT_APP_URL}/licences/execute-updater`, {
                method: 'POST',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    licenseKey: systemData.licenceKey,
                    licenseType: licenseType,
                    licenseRevision: process.env.REACT_APP_BUILD_VERSION,
                    licenseToken: !!hasUpdate.internet ? hasUpdate.token : "F[ur4(.F{LqLlB2C;[.ZQU:)PaczcH2W",
                    licenseInternet: hasUpdate.internet
                })
            });
        }
        catch (error)
        {
            console.log("🚀 ~ ExecuteUpdater ~ error:", error);
        }
        finally
        {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        console.log("🚀 ~ CheckModemConnection ~ setGoAway:", goAway);
    }, [goAway]);

    const GetModemStatus = useCallback(() => {
        CheckPortCOM();
        ControlModemButton();
    },[CheckPortCOM, ControlModemButton]);

    useEffect(ControlModemButton,[ControlModemButton, modemStatus]);

    useEffect(() => {
        if(!!rows)
        {           
            if(!!rows.fK_OCORRENCIA)
            {
                console.log("🚀 ~ useEffect ~ rows.fK_OCORRENCIA:", rows.fK_OCORRENCIA)
                let occurrence = occurrenceController;

                occurrence.last = rows;
                occurrence.triggeredIn = (new Date(rows.dT_EVENTO)).toLocaleString().slice(0, 20).replace(/-/g, "/").replace("T", " ").replace(",","");
                
                setOccurrenceController(occurrence);
                ControlActionButton(occurrence.last.fK_OCORRENCIA);
            }
        }
    },[rows]);

    useEffect(() => {
        if (!!sessionStorage.getItem("serial"))
        {
            if (process.env.REACT_APP_MODE === "desktop")
            {
                const interval = setInterval(() => { GetNetworkData(sessionStorage.getItem("serial"), token) },90000);
    
                return () => clearInterval(interval);
            }
            else
            {
                const interval = setInterval(() => { GetNetworkData(sessionStorage.getItem("serial"), token) },300000);
    
                return () => clearInterval(interval);
            }
        }
        else
        {
            setTimeout(() => {
                console.log("Sem serial, nova tentativa.");

                if (process.env.REACT_APP_MODE === "desktop")
                {
                    const interval = setInterval(() => { GetNetworkData(sessionStorage.getItem("serial"), token) },90000);
        
                    return () => clearInterval(interval);
                }
                else
                {
                    const interval = setInterval(() => { GetNetworkData(sessionStorage.getItem("serial"), token) },300000);
        
                    return () => clearInterval(interval);
                }
            }, 10000)
        }

    },[GetNetworkData, serial])

    useEffect(() => {
        if(process.env.REACT_APP_MODE === "desktop")
        {
            GetModemStatus();
        }
        else
        {
            CheckModemConnection();
        }
    },[])

    useEffect(() => {

        CheckTypeOfView();

        if (localStorage.getItem('df-guardiao-type-view') === 'touch')
        {
            setTouchMenuControl(true);
        }
        else
        {
            setTouchMenuControl(false);
        }

        if(!!systemData.serialKeys && typeof systemData.serialKeys !== 'undefined')
        {
            sessionStorage.setItem("serial", systemData.serialKeys);
        }
    },[ControlActionButton, systemData.serialKeys]);

    return (
        <>
            <Snackbar
                open={notifyNetworkData}
                autoHideDuration={6000}
                onClose={closeSnackBarNetwork}
                message="Informações Atualizadas!" 
            />
            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201"}} /> 
            }
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar className="app-header" position="fixed" open={open} elevation={0} data-dark-mode={darkMode}>
                    <ToolBar className="app-header__container" elevation={0}>
                        <div class="app-header__container__wrap app-header__container__wrap--left">
                            <Box className="item1" sx={{ my: 'auto' }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginRight: 5,
                                        [theme.breakpoints.down('sm')]: {
                                            display: 'none'
                                        },
                                        ...(open && { display: 'none' }),
                                    }}
                                > <MenuIcon />
                                </IconButton>

                                <IconButton
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    aria-expanded={mobileMenuOpen ? 'true' : undefined}
                                    onClick={handleClick}
                                    edge="start"
                                    sx={{
                                        marginRight: 5,
                                        [theme.breakpoints.up('sm')]: {
                                            display: 'none'
                                        },
                                        //...(open && { display: 'none' }),
                                    }}
                                > <MenuIcon />
                                </IconButton>
                            </Box>

                            <figure class="logo">
                                {userRole.tecnico === true 
                                ? 
                                <ImageWrapper sx={{ my: 'auto' }} className="item2" src={LogoTecnico} alt={"Guardião Delta Fire"} /> 
                                : 
                                userRole.revenda === true 
                                ? 
                                <ImageWrapper sx={{ my: 'auto' }} className="item2" src={LogoRevenda} alt={"Guardião Delta Fire"} /> 
                                : 
                                <ImageWrapper sx={{ my: 'auto' }} className="item2" src={LogoCliente} alt={"Guardião Delta Fire"} />
                                }
                            </figure>
                        </div>

                        <div class="app-header__container__wrap app-header__container__wrap--center">
                            <button class="header-action" data-action="refresh-system" title="Atualizar Informações de Sistema" onClick={RefreshNetworkData}>
                                <i class="fa fa-refresh fa-lg" aria-hidden="true" style={{"marginTop": "2px"}}></i>
                            </button>
                            {process.env.REACT_APP_MODE === "desktop" &&
                                <button class="header-action" data-action="health-system" title="Ir para Sistema" onClick={() => history.push("/central")}>
                                    <i class="df-i df-i-xl df-i-system"></i>
                                </button>
                            }

                            {/* <label for="ocurrence-backdrop" class={!!disabledOcurrenceBackdrop ? "header-action" : "header-action header-action--disabled"} data-action="system-alerts" title="Ver Alertas">
                                <i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true" style={{"marginTop": "2px"}}></i>
                            </label> */}
                            <AlarmBackdrop 
                                userRole={userRole} 
                                companyId={companyId} 
                                userId={userId} 
                                serial={serial} 
                                token={token} 
                                rows={rows} 
                                clearState={clearState} 
                                generatedCommand={generatedCommand} 
                                clearBackdrop={clearBackdrop} 
                                modemStatus={modemStatus}
                                occurrenceController={occurrenceController}
                                setOccurrenceController={setOccurrenceController}
                                title="Ver alertas"
                            />
                            {process.env.REACT_APP_MODE === "desktop" &&
                            <>
                                <figure class="header-action" data-action="port-com" data-tippy data-tippy-placement="bottom" data-tippy-content="Verificando Modem">
                                    <i class="fa fa-usb fa-rotate-270" aria-hidden="true"></i>
                                </figure>
                                <label class="header-action header-action--danger" data-action="commands" title="Comandos de Emergência" for="control-top-drawer">
                                    <i class="df-i df-i-xl df-i-emergency" data-type="comandos"></i>
                                </label>
                            </>
                            }
                        </div>

                        <div class="app-header__container__wrap app-header__container__wrap--right">
                            <Box sx={{ width: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    sx={{ height: '100%' }}
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
									<i class="fa fa-user" aria-hidden="true" style={{"font-size": "20px", "margin-right": "5px"}}></i>
                                    <Typography variant="body1">{user}</Typography>
                                </IconButton>
                            </Box>

                            {/* <IconButton onClick={handleDarkMode}>
                                {darkMode
                                    ?
                                    <LightModeIcon />
                                    :
                                    <DarkModeIcon />
                                }

                            </IconButton> */}
                        </div>

                        <Menu
                            id="menu-appbar-user"
                            anchorEl={anchorUserEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorUserEl)}
                            onClose={handleCloseUser}
                        >
                            {process.env.REACT_APP_MODE === "desktop" &&
                            <MenuItem onClick={() => { VerifyUpdate() }}>
                                <ListItemIcon>
                                    <i class='bx bx-cloud-download bx-sm'></i>
                                </ListItemIcon>
                                <ListItemText>
                                    Atualizar
                                </ListItemText>
                            </MenuItem>
                            }
                            <MenuItem onClick={() => {
                                logout()
                            }}>
                                <ListItemIcon>
                                    <i class='bx bx-exit bx-sm' ></i>
                                </ListItemIcon>
                                <ListItemText>
                                    Sair
                                </ListItemText>
                            </MenuItem>
                        </Menu>

                        <Menu
                            className="menu-mobile"
                            data-dark-mode={darkMode}
                            anchorEl={anchorEl}
                            open={mobileMenuOpen}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {userRole.light === true
                                ? menuLightVersion.map((item, index) => (
                                    process.env.REACT_APP_MODE === item.mode || item.mode === "all" ?
                                    <MenuItem
                                        className="menu-mobile__item"
                                        key={index}  // Considerando usar um identificador mais único se possível
                                        onClick={() => handleClose(item.path)}
                                    >
                                        {item.icon}
                                        {item.text}
                                    </MenuItem>
                                    : null
                                ))
                                : menuItens.map((item, index) => (
                                    process.env.REACT_APP_MODE === item.mode || item.mode === "all" ?
                                    <MenuItem
                                        className={item.text === "Emergência" ? "menu-mobile__item menu-mobile__item--comandos" : "menu-mobile__item"}
                                        key={index}  // Considerando usar um identificador mais único se possível
                                        onClick={() => handleClose(item.path)}
                                    >
                                        {item.icon}
                                        {item.text}
                                    </MenuItem>
                                    : null
                                ))
                            }

                            {userRole.revenda === true || userRole.delta === true || userRole.multiCentral === true ? <MenuItem>
                                <Button onClick={() => handleReturn()}>Voltar ao Painel</Button>
                            </MenuItem> : null}
                        </Menu>

                    </ToolBar>
                </AppBar>

                {process.env.REACT_APP_MODE === "desktop" &&
                <Modal modalId="modal-update-guardiao">
                    <Modal.Header>
                        <h5 class="modal__title">Verificação</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="text-center px-2">
                            {
                                !!hasUpdate.update
                                ?
                                <>
                                    <i class='bx bx-check-shield bx-lg bx-burst' animation-iteration-count="1"></i>
                                    <p dangerouslySetInnerHTML={{ __html: modalUpdateMessage }}></p>
                                    <div class="hint-box">
                                        <i class='bx bx-info-circle bx-sm hint-box__icon'></i>
                                        <small class="hint-box__content">
                                            <strong>
                                                Ao clicar em Atualizar, você deverá aguardar a execução do processo completo.
                                                <br/>
                                                Após, refaça o login no Guardião 2.0.
                                            </strong>
                                        </small>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        !hasUpdate.internet
                                        ?
                                        <i class='bx bx-no-signal bx-lg bx-burst' animation-iteration-count="1"></i>
                                        :
                                        <i class='bx bx-check-shield bx-lg bx-burst' animation-iteration-count="1"></i>
                                    }
                                    <p dangerouslySetInnerHTML={{ __html: modalUpdateMessage }}></p>
                                    {
                                        !hasUpdate.internet &&
                                        <div class="hint-box">
                                            <i class='bx bx-info-circle bx-sm hint-box__icon'></i>
                                            <small class="hint-box__content">
                                                <strong>
                                                    <u>Para o técnico:</u> Tenha em mãos o <u>arquivo de atualização</u>.
                                                    <br/>
                                                    Ao clicar em Atualizar Offline, você deverá aguardar a execução do processo.
                                                    <br/>
                                                    Após isso, refaça o login no Guardião 2.0.
                                                </strong>
                                            </small>
                                        </div>
                                    }
                                </>
                            }
                        </div>            
                    </Modal.Body>
                    <Modal.Footer>
                        <div class="buttons-container buttons-container--center buttons-container--invert-mobile mb-3 px-5">
                            {
                                (!!hasUpdate.update) || (!hasUpdate.update && !hasUpdate.internet)
                                ?
                                <>
                                    <label for="modal-update-guardiao" class="button button--default button--sm" title="Clique para cancelar o processo de atualização">
                                        <span class="button__label">
                                            Cancelar
                                        </span>
                                    </label>
                                    {
                                        !!hasUpdate.internet
                                        ?
                                        <button 
                                            type="button" 
                                            class="button button--primary button--sm" 
                                            title="Clique para executar o Atualizador"
                                            onClick={() => { ExecuteUpdater() }}>
                                            <span class="button__label">
                                                Atualizar
                                            </span>
                                        </button>
                                        :
                                        <button 
                                            type="button" 
                                            class="button button--primary button--sm" 
                                            title=" Atualizar Offline para uso do Técnico"
                                            onClick={() => { ExecuteUpdater() }}>
                                            <span class="button__label">
                                                Atualizar Offline
                                            </span>
                                        </button>
                                    }
                                </>
                                :
                                <>
                                    <label for="modal-update-guardiao" class="button button--primary button--sm">
                                        <span class="button__label">
                                            Ok
                                        </span>
                                    </label>
                                </>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
                }

                {/* <Drawer className="drawer--bottom-full drawer--priority-4" drawerId={`ocurrence-backdrop`}>
                    <Drawer.Body>
                        <OccurencesHandler
                            userRole={userRole} 
                            companyId={companyId} 
                            userId={userId} 
                            serialNumber={serial} 
                            token={token} 
                            rows={rows} 
                            clearState={clearState} 
                            generatedCommand={generatedCommand} 
                            clearBackdrop={clearBackdrop} 
                            modemStatus={modemStatus}
                            occurrenceController={occurrenceController}
                            setOccurrenceController={setOccurrenceController}
                        />
                    </Drawer.Body>
                </Drawer> */}

                {process.env.REACT_APP_MODE === "desktop" &&
                <>
				{/* Componente Drawer */}
				<input type="checkbox" id="control-top-drawer" data-control="drawer"/>
                <div class="drawer drawer--top drawer--priority-5">
                    <label for="control-top-drawer" class="drawer__close">
						<i class="fa fa-times fa-lg" aria-hidden="true"></i>
					</label>
					<div class="drawer__header">
						<h3 class="drawer__header__label">Comandos para: Geral</h3>
					</div>
					<div class="drawer__content">
						<PanelCommands 
                            generatedCommand={generatedCommand} 
							user={companyId} 
                            serialNumber={serial}
                            token={token}
                            modemStatus={modemStatus}
                            occurrenceController={occurrenceController}
                            setOccurrenceController={setOccurrenceController}
						/>
                    </div>
                </div>
				{/* Componente Drawer */}
                </>
                }

                <nav class="menu-lateral" data-dark-mode={darkMode} data-view-touch={touchMenuControl}>
                    <ul class="menu-lateral__list">
                        {userRole?.light === true
                            ? menuLightVersion?.map(item => (
                                process.env.REACT_APP_MODE === item.mode || item.mode === "all" ?
                                <li key={item.path} className={location.pathname === item.path ? "menu-lateral__list__item menu-lateral__list__item--selected" : "menu-lateral__list__item"}>
                                    <div className="menu-lateral__list__item__icon">
                                        {item.icon}
                                    </div>
                                    <div className="menu-lateral__list__item__label" onClick={() => history.push(item.path)}>
                                        <Typography color={item.text === "Emergência" ? 'error' : null}>{item.text}</Typography>
                                    </div>
                                </li>
                                : null
                            ))
                            : menuItens?.map(item => (
                                process.env.REACT_APP_MODE === item.mode || item.mode === "all" ?
                                    item.path.includes("configuracoes") && item.mode === "web" && !JSON.parse(localStorage.getItem('user')).booL_MASTER ?
                                    null
                                    :
                                    <li key={item.path} className={location.pathname === item.path ? "menu-lateral__list__item menu-lateral__list__item--selected" : "menu-lateral__list__item"}>
                                        <div className="menu-lateral__list__item__icon">
                                            {item.icon}
                                        </div>
                                        <div className="menu-lateral__list__item__label" onClick={() => history.push(item.path)}>
                                            <Typography color={item.text === "Emergência" ? 'error' : null}>{item.text}</Typography>
                                        </div>
                                    </li>
                                : null
                            ))
                        }
                        {userRole.revenda === true || userRole.delta === true || userRole.multiCentral === true ?
                            <li className={"menu-lateral__list__item"}>
                                <div className="menu-lateral__list__item__icon">
                                    <KeyboardReturnIcon />
                                </div>
                                <div className="menu-lateral__list__item__label" onClick={() => handleReturn()}>
                                    <Typography>Voltar ao Painel</Typography>
                                </div>
                            </li>
                            : null}
                    </ul>
                </nav>

                <div className={darkMode ? classes.pageLayoutDark : classes.pageLayout}>
                    <PageMargin></PageMargin>
                    {children}
                </div>
                {serial != null
                    ? <Footer key={serial} open={open} drawerWidth={drawerWidth} serial={serial} />
                    : null
                }
            </Box>
        </>
    )
}